body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 0.5s;
}

main {
  background: $body-bg;
}

// Buttons
.btn {
  text-transform: uppercase;
  transition: all .3s ease;
}
.btn-primary {
  padding: 10px 20px;

  &:hover {
    background: #fff;
    color: #000;
    border-color: #fff;
  }
}


// Loader
@keyframes ball-zag-deflect {
  17% {
    transform: translate(15px, 30px);
  }
  34% {
    transform: translate(-15px, 30px);
  }
  50% {
    transform: translate(0, 0);
  }
  67% {
          transform: translate(-15px, 30px);
  }
  84% {
            transform: translate(15px, 30px);
  }
  100% {
            transform: translate(0, 0);
  }
}
@keyframes ball-zig-deflect {
  17% {
    transform: translate(-15px, -30px);
  }
  34% {
    transform: translate(15px, -30px);
  }
  50% {
    transform: translate(0, 0);
  }
  67% {
    transform: translate(15px, -30px);
  }
  84% {
    transform: translate(-15px, -30px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: #000;
    transition: 0.8s;
}

.no-js .loader {
	display: none;
}

.loader-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.ball-zig-zag-deflect {
  position: relative;
  transform: translate(-15px, -15px);
}
.ball-zig-zag-deflect > div {
  background-color: #fff;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 2px;
  animation-fill-mode: both;
  position: absolute;
  margin-left: 15px;
  top: 4px;
  left: -7px;
}
.ball-zig-zag-deflect > div:first-child {
  animation: ball-zig-deflect 1.5s 0s infinite linear;
}
.ball-zig-zag-deflect > div:last-child {
  animation: ball-zag-deflect 1.5s 0s infinite linear;
}

// Animate
.animate-box {
	.js & {
		opacity: 0;
	}
}



// 404
#page-not-found {
  position: relative;
  background: url(../images/ss2018-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  width: 100%;
  height: 100vh;

  &:after {
    position: absolute;
    content: '';
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(#000,.6);
  }

  .header {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    max-width: 600px;
    padding: 30px;
    text-align: center;
    transform: translate(-50%,-50%);
    background: rgba(#000,.7);
    z-index: 2;

    h1 {
    font-size:  120px;

      span {
        display: block;
        font-size: 36px;
        letter-spacing: 2px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }

    .message {
      margin-bottom: 30px;
    }

  }
}


// Popup
.white-popup {
  position: relative;
  background: #FFF;
//  padding: 20px;
  width: 90%;
  max-width: 480px;
  margin: 20px auto;

  @media(max-width: $screen-sm) {
     video {
      width: 100% !important;
      height: auto !important;
    }
  }

}

//
//video {
//   width: 100%;
//   max-width: 720px;
//   height: auto;
//}
