.banner {
  position: fixed;
  width: 100%;
  margin: 20px 0;
  background: transparent;
  z-index: 100;
  text-align: center;

  @media(min-width: $screen-sm) {
    text-align: left;
  }

  .brand {
    position: relative;
    display: inline-block;
    width: 160px;
    height: 40px;
    text-indent: -99999px;
    background-image: url('../images/craft-logo-neg.png');
    background-repeat: no-repeat;
    background-size: 160px 40px;
    margin-bottom: 15px;

    .fp-viewing-home & {
      background-image: url('../images/craft-logo.png');
    }
  }

  .nav-primary {
    display: block;
    padding-right: 50px;

    @media(min-width: $screen-sm) {
      display: inline-block;
      float: right;
    }

    .nav {
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: .3px;

      > li {
        @media(min-width: $screen-sm) {
          display: inline-block;
        }
        > a {
          color: #fff;
          text-shadow: 0 0.3rem 0.9rem rgba(0,0,0,.4);


          .fp-viewing-home & {
            color: #000;
            text-shadow: none;
          }

          &:hover,
          &:focus,
          &:active {
            background: transparent;
          }
        }
      }
    }
  }

}

.svg {
  .brand {
    background-image: url('../images/craft-logo-neg.svg');

    .fp-viewing-home & {
      background-image: url('../images/craft-logo.svg');
    }
  }
}
