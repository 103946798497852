/* Overlay style */
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.95);
}

/* Overlay closing cross */
//.overlay .overlay-close {
//	width: 80px;
//	height: 80px;
//	position: absolute;
//	right: 20px;
//	top: 20px;
//	overflow: hidden;
//	border: none;
//	background: url(../img/cross.png) no-repeat center center;
//	text-indent: 200%;
//	color: transparent;
//	outline: none;
//	z-index: 100;
//}

/* Menu style */
.overlay nav {
	text-align: center;
	position: relative;
	top: 50%;
	height: 45%;
	transform: translateY(-50%);

    @media(min-width: $screen-sm) {
      height: 60%;
    }
}

.overlay ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: inline-block;
	height: 100%;
	position: relative;
}

.overlay ul li {
	display: block;
	height: 20%;
	height: calc(100% / 5);
	min-height: 54px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.overlay ul li a {
	font-size: 24px;
	font-weight: 300;
	display: block;
    background: transparent;
    text-transform: uppercase;
    letter-spacing: -1px;
    font-weight: 700;
	color: rgba(255,255,255,.8);
	transition: color 0.2s;

    @media(min-width: $screen-sm){
      font-size: 36px;
    }
    @media(min-width: $screen-sm){
      font-size: 54px;
    }
}

.overlay ul li a:hover,
.overlay ul li a:focus {
	color: #fff;
    background: transparent;
}

/* Effects */
.overlay-scale {
	visibility: hidden;
	opacity: 0;
	transform: scale(0.9);
	transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.overlay-scale.open {
	visibility: visible;
	opacity: 1;
	transform: scale(1);
	transition: transform 0.4s, opacity 0.4s;
}

@media screen and (max-height: 30.5em) {
	.overlay nav {
		height: 70%;
		font-size: 34px;
	}
	.overlay ul li {
		min-height: 34px;
	}
}


.toggle {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 !important;
  margin-right: 15px;
  margin-top: 9px;
}

#nav-icon {
  width: 30px;
  height: 22px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  border-radius: 0;
  background: #fff;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;

  .fp-viewing-home & {
    background: #000;
  }
}

#nav-icon.open span {
  background: #fff;
}


#nav-icon span:nth-child(1) {
  top: 0px;
  transform-origin: left center;
}

#nav-icon span:nth-child(2) {
  top: 9px;
  transform-origin: left center;
}

#nav-icon span:nth-child(3) {
  top: 18px;
  transform-origin: left center;
}

#nav-icon.open span:nth-child(1) {
  transform: rotate(45deg);
  top: -2px;
  left: 4px;
}

#nav-icon.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-icon.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 19px;
  left: 4px;
}
