#hero {
  position:relative;

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 3;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(#312936,0.3);
    z-index: 2;
  }

  .video-bg {
    position: relative;
    z-index: 1;
  }
  .background {
    position: relative;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 5;
  }

}

.section {
   position: relative;

   .fp-bg{
      background-size: cover;
      transform: translate3d(0,0,0);
      background-position: top center;
      backface-visibility: hidden;
  }
}

.section {
  color: #fff;
  text-shadow: 0 0.3rem 0.9rem rgba(0,0,0,.4);

  h2 {
    text-transform: uppercase;
    font-size: 36px;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: -1px;
    text-shadow: 0 0.3rem 0.9rem rgba(0,0,0,.4);

    @media(min-width: $screen-sm) {
      font-size: 54px;
    }
  }

  h2.small {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -1px;
    margin-bottom: 15px;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(#000,0.3);
    z-index: 1;
  }

    .intro-col {
       position: relative;



      .logo {
        margin-bottom: 15px;
      }

        a {
          outline: 0;
        }


        .ico-play {
          padding: 50px;
          outline: 0;
          color: rgba(255,255,255,.5);
          font-size: 200px;
          text-shadow: 0px 0px 10px rgba(0,0,0,.15);
          transition: all .4s ease-in-out;

          &:hover {
            color: rgba(255,255,255,1);
            text-shadow: 0px 0px 30px rgba(0,0,0,.8);
          }

          i {
            outline: 0;

            &:focus {
              outline: 0;
            }
          }
      }
    }

    p {
      margin-bottom: 20px;
    }

}

// silhouette
.silhouette {

  box-shadow: 0px 10px 30px rgba(#000,.2);

  .silhouette-bg {
    position: relative;
    overflow: hidden;
    width: 100%;
    left: 0;
    right: auto;
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;

    img {
      opacity: 0;
    }

  }
  .silhouette-box {
    position: relative;
    margin: 0 auto;
    width: 100%;

    .box-inner {
      position: relative;
      text-align: center;
      width: 100%;
      bottom: 0;
      padding: 30px 50px;
      background: #FFF;

      h3 {
        margin-top: 0;
        text-transform: uppercase;
        font-size: 15px;
        margin-bottom: 20px;
        text-shadow: none;
        color: #000;

        @media(min-width: $screen-sm) {
          font-size: 18px;
        }
      }

    }

  }
}


// 3d carousel
.silhouette-carousel {

  .owl-nav {
    font-size: 30px;
    color: #000;

    .owl-prev,
    .owl-next {
      padding: 0px 10px;
      display: inline-block;
      text-shadow: none;

    }
  }
  .owl-item {
    text-align: center;
    position: relative;

    img {
      width: auto;
      display: inline-block;
    }
    &:after {
      content: "";
      position: absolute;
      z-index: -1;
      box-shadow: 0 0 10px rgba(0,0,0,0.4);
      background: #f1f1f1;
      bottom: 10px;
      left: 20%;
      right: 20%;
      width: 60%;
      height: 1px;
      border-radius: 50%;
    }
  }

}


// icon scroll
.icon-scroll {
  position: absolute;
  left: 50%;
  width: 20px;
  height: 35px;
  margin-left: -10px;
  position: absolute;
  bottom: 20px;
  margin-top: -35px;
  box-shadow: inset 0 0 0 3px #000;
  border-radius: 25px;
  z-index: 99;

  &:before {
    position: absolute;
    left: 50%;
    content: '';
    width: 4px;
    height: 4px;
    background: #000;
    margin-left: -2px;
    top: 4px;
    border-radius: 4px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: scroll;
  }
}

@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(23px);
  }
}


.fp-controlArrow.fp-prev {
  border-width: 0px !important;
  width: 20px;
  height: 40px;

  &:before {
    content: "\f3d2";
    font-family: 'Ionicons';
    color: #fff;
    display: inline-block;
    font-size: 54px;
    line-height: 1em;
  }

}
.fp-controlArrow.fp-next {
  border-width: 0px !important;
  width: 20px;
  height: 40px;

  &:before {
    content: "\f3d3";
    font-family: 'Ionicons';
    color: #fff;
    display: inline-block;
    font-size: 54px;
    line-height: 1em;
  }
}
