// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #000;

// Scaffolding
$text-color: #000;
$body-bg: #f1f1f6;

// Typography
$font-family-sans-serif : 'Montserrat', "Helvetica Neue", Helvetica, Arial, sans-serif;


// Buttons
$btn-primary-color: #fff;
$btn-primary-bg: transparent;
$btn-primary-border: #fff;

$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;
