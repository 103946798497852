.content-info {
  text-align: center;
//  background: #000;
  color: #fff;
  padding: 60px 0;

  .tagline {
    display: inline-block;
    text-indent: -9999px;
    width: 290px;
    height: 60px;
    background: url('../images/craft-tagline-neg.png');
    background-repeat: no-repeat;
    background-size: 290px 60px;
    margin-bottom: 30px;
  }

  .brand {
    margin-bottom: 30px;
    position: relative;
    display: inline-block;
    width: 160px;
    height: 40px;
    text-indent: -99999px;
    background: url('../images/craft-logo-neg.png');
    background-repeat: no-repeat;
    background-size: 160px 40px;
  }

  .social-icons {
    margin-bottom: 30px;
    display: block;

    > li {
      display: inline-block !important;

      a {
        display: inline-block;
        border: 2px solid #fff;
        color: #fff;
        font-size: 20px;
        width: 36px;
        height: 36px;
        line-height: 32px;
        transition: all .3s ease-in-out;

        &:hover {
          color: rgba(#fff, 0.5);
          border-color: rgba(#fff, 0.5);
        }
      }
    }
  }
  .copyrights {
    color: #444;

    a {
      color: #444;
      &:hover {
        color: #666;
      }
    }
  }
}

.svg {
  .content-info {
    .tagline {
      background: url('../images/craft-tagline-neg.svg');
    }
    .brand {
      background: url('../images/craft-logo-neg.svg');
    }
  }
}
